import * as actionTypes from "../../constants/action-types/filters";

const INIT_STATE = {};

const filters = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTERS: {
            const {key, filters} = action.payload;

            return {...state, [key]: filters};
        }
        default:
            return {...state};
    }
};

export default filters;
