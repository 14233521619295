import * as actionTypes from '../../constants/action-types/plans';

export const sortPlans = { type: actionTypes.SORT_PLANS }

export const fetchPlans = (page) => ({
    type: actionTypes.FETCH_PLANS,
    payload: { page }
});

export const fetchPlansStart = () => ({
    type: actionTypes.FETCH_PLANS_START
});

export const fetchPlansSuccess = (plans, paginator) => ({
    type: actionTypes.FETCH_PLANS_SUCCESS,
    payload: { plans, paginator }
});

export const fetchPlansFailure = () => ({
    type: actionTypes.FETCH_PLANS_FAILURE
});

export const receivePlans = (plans) => ({
    type: actionTypes.RECEIVE_PLANS,
    payload: { plans }
});
