import React, {Component} from 'react';
import {Link} from 'react-router-dom'

class NotFoundPage extends Component {
    componentDidMount() {
        document.body.classList.add('authentication-bg');
        document.body.classList.add('authentication-bg-pattern');
    }

    componentWillUnmount() {
        document.body.classList.remove('authentication-bg');
        document.body.classList.remove('authentication-bg-pattern');
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages mt-5 mb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card">

                                    <div className="card-body p-4">
                                        <div className="text-center">
                                            <h3 className="mt-0">Page not found </h3>
                                            <p className="text-muted mb-0">It's looking like you may have taken a wrong
                                                turn. Don't worry...
                                                it happens to the best of us. You might want to check your internet
                                                connection.
                                                Here's a little tip that might help you get back on track.</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12 text-center">
                                        <p className="text-white-50">
                                            Return to <Link to="/" className="text-white ml-1"><b>Home</b></Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default NotFoundPage;