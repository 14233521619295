import {combineReducers} from 'redux';
import auth from './auth/reducers';
import profile from './profile/reducers';
import users from './users/reducers';
import plans from './plans/reducers';
import schools from './schools/reducers';
import students from './students/reducers';
import filters from './filters/reducers';

import planTypes from './plan-types/reducers';
import customFields from './custom-fields/reducers';

import {LOGOUT} from "../constants/action-types/auth";

const appReducer = combineReducers({
    auth, profile, users, plans, schools, planTypes, customFields, students, filters
});

export default (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined
    }

    return appReducer(state, action)
};
