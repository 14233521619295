import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {
    fetchSchoolsStart,
    fetchSchoolsSuccess,
    fetchSchoolsFailure,
    fetchSchoolStart,
    fetchSchoolSuccess,
    fetchSchoolFailure
} from './actions';
import api from "../../helpers/api";
import * as actionTypes from "../../constants/action-types/schools";

export function* watchFetchSchools(): any {
    yield takeLatest(actionTypes.FETCH_SCHOOLS, function* () {
        try {
            yield put(fetchSchoolsStart());

            const response = yield call(() => {
                return api.fetchSchools();
            });

            const {data} = response.data;

            yield put(fetchSchoolsSuccess(data));
        } catch {
            yield put(fetchSchoolsFailure());
        }
    });
}

export function* watchFetchSchool(): any {
    yield takeLatest(actionTypes.FETCH_SCHOOL, function* (action) {
        try {
            yield put(fetchSchoolStart());

            const response = yield call(() => {
                return api.fetchSchool(action.payload.id);
            });

            const {data} = response.data;

            yield put(fetchSchoolSuccess(data));
        } catch (error) {
            yield put(fetchSchoolFailure());
        }
    });
}

function* schoolsSaga(): any {
    yield all([
        fork(watchFetchSchools),
        fork(watchFetchSchool)
    ]);
}

export default schoolsSaga;
