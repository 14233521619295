import {all} from 'redux-saga/effects';
import authSaga from './auth/saga';
import profileSaga from './profile/saga';
import usersSage from './users/saga';
import plansSage from './plans/saga';
import schoolsSage from './schools/saga';


export default function* rootSaga() {
    yield all([
        authSaga(), profileSaga(), usersSage(), plansSage(), schoolsSage()
    ]);
}
