import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import { routes } from './routes';
import { isUserAuthenticated } from './helpers/auth';
import './assets/scss/DefaultTheme.scss';
import NotFoundPage from "./pages/NotFoundPage";
import Loader from "./components/Loader";
import { fetchMe } from "./redux/profile/actions";
import 'react-quill/dist/quill.snow.css'; // ES6
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from "react-tooltip";

const loading = () => <div />;

const NonAuthLayout = Loadable({
    loader: () => import('./layouts/NonAuthLayout'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading
});

const AuthLayout = Loadable({
    loader: () => import('./layouts/AuthLayout'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading
});

const withLayout = (WrappedComponent) => {
    const HOC = class extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return connect()(HOC);
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
        if (isUserAuthenticated()) this.props.fetchMe();
    }

    getLayout = (route) => {
        const exceptPaths = ['/change-password'];
        return isUserAuthenticated() && exceptPaths.indexOf(route.path) === -1 ? AuthLayout : NonAuthLayout;
    };

    isLoading() {
        return isUserAuthenticated() && !this.props.user;
    }

    render() {
        return (
            this.isLoading() ? <Loader /> :
                <BrowserRouter>
                    <React.Fragment>
                        <Switch>
                            {routes.map((route, index) => {
                                return (
                                    <route.route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        component={withLayout(props => {
                                            const Layout = this.getLayout(route);
                                            return (
                                                <Suspense fallback={loading()}>
                                                    <Layout {...props}>
                                                        <route.component {...props} />
                                                    </Layout>
                                                </Suspense>
                                            );
                                        })}
                                        roles={route.roles}
                                    />
                                );
                            })}
                            <Route component={NotFoundPage} exact={true} />
                        </Switch>
                    </React.Fragment>
                    <ToastContainer/>
                    <ReactTooltip/>
                </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.profile;
    return { user };
};

export default connect(mapStateToProps, { fetchMe })(App);
