import React, { Component } from 'react';

class PreLoaderWidget extends Component {
    render() {
        const { isBPG } = this.props
        return (
            <>
                {isBPG ?
                    <div className="">
                        <div className="status">
                            <div style={{ height: '1.3rem', width: '1.3rem' }} className='spinner-border  text-primary m-2' role="status" />
                        </div>
                    </div>

                    :
                    <div className="preloader">
                        <div className="status">
                            <div className='spinner-border avatar-sm text-primary m-2' role="status" />
                        </div>
                    </div>
                }

            </>
        )
    }
}

export default PreLoaderWidget;