import * as profile from '../../constants/action-types/profile';

export const fetchMe = () => ({
    type: profile.FETCH_ME
});

export const fetchMeStart = () => ({
    type: profile.FETCH_ME_START
});

export const fetchMeSuccess = (user) => ({
    type: profile.FETCH_ME_SUCCESS,
    payload: {user}
});

export const fetchMeFailed = () => ({
    type: profile.FETCH_ME_FAILED
});

export const passwordChanged = () => ({
    type: profile.PASSWORD_CHANGED
});

