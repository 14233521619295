import * as actionTypes from '../../constants/action-types/users';

export const fetchUsers = (filters) => ({
    type: actionTypes.FETCH_USERS, payload: {filters}
});

export const fetchUsersStart = () => ({
    type: actionTypes.FETCH_USERS_START
});

export const fetchUsersSuccess = (users, paginator) => ({
    type: actionTypes.FETCH_USERS_SUCCESS, payload: {users, paginator}
});

export const fetchUsersFailure = () => ({
    type: actionTypes.FETCH_USERS_FAILURE
});