import * as actionTypes from '../../constants/action-types/auth';

const INIT_LOGIN = {
    error: null,
    loading: false
};

const INIT_FORGET_PASSWORD = {
    error: null,
    loading: false,
    success: false
};

const INIT_RESET_PASSWORD = {
    errors: [],
    loading: false,
    success: false
};

const INIT_STATE = {
    login: INIT_LOGIN,
    forgetPassword: INIT_FORGET_PASSWORD,
    resetPassword: INIT_RESET_PASSWORD
};


const auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_OPEN:
            return {...state, login: {...INIT_LOGIN}};
        case actionTypes.LOGIN_START:
            return {...state, login: {...state.login, loading: true, error: null}};
        case actionTypes.LOGIN_SUCCESS:
            return {...state, login: {...state.login, loading: false, error: null}};
        case actionTypes.LOGIN_FAILED:
            return {...state, login: {...state.login, loading: false, error: action.payload.error}};

        case actionTypes.FORGET_PASSWORD_OPEN:
            return {...state, forgetPassword: {...INIT_FORGET_PASSWORD}};
        case actionTypes.FORGET_PASSWORD:
            return {...state, forgetPassword: {...state.forgetPassword, loading: true}};
        case actionTypes.FORGET_PASSWORD_SUCCESS:
            return {...state, forgetPassword: {...state.forgetPassword, loading: false, error: null, success: true}};
        case actionTypes.FORGET_PASSWORD_FAILED:
            return {...state, forgetPassword: {...state.forgetPassword, error: action.payload, loading: false}};

        case actionTypes.RESET_PASSWORD_OPEN:
            return {...state, resetPassword: {...INIT_RESET_PASSWORD}};
        case actionTypes.RESET_PASSWORD:
            return {...state, resetPassword: {...state.resetPassword, loading: true}};
        case actionTypes.RESET_PASSWORD_SUCCESS:
            return {...state, resetPassword: {...state.resetPassword, loading: false, errors: [], success:true}};
        case actionTypes.RESET_PASSWORD_FAILED:
            return {...state, resetPassword: {...state.resetPassword, errors: action.payload, loading: false}};

        default:
            return {...state};
    }
};

export default auth;
