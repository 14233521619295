import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { fetchPlansStart, fetchPlansSuccess, fetchPlansFailure } from './actions';
import api from "../../helpers/api";
import { FETCH_PLANS } from "../../constants/action-types/plans";

export function* watchFetchPlans(): any {
    yield takeLatest(FETCH_PLANS, function* (action) {
        try {
            yield put(fetchPlansStart());

            const response = yield call(() => {
                return api.fetchPlans({ page: action.payload.page });
            });

            const { data } = response.data;
            yield put(fetchPlansSuccess(data));
        } catch {
            yield put(fetchPlansFailure());
        }
    });
}

function* plansSaga(): any {
    yield all([
        fork(watchFetchPlans)
    ]);
}

export default plansSaga;
