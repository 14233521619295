import * as actionTypes from '../../constants/action-types/schools';
import {merge} from "../../helpers/generic";

const INIT_STATE = {
    fetched: [],
    schools: {ids: [], loading: false},
    school: {id: null, loading: false}
};

const schools = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SCHOOLS:
            return {...state, schools: {...state.schools, loading: true}};
        case actionTypes.FETCH_SCHOOLS_SUCCESS:
            return {
                ...state,
                fetched: merge(state.fetched, action.payload.schools),
                schools: {
                    ...state.schools,
                    ids: action.payload.schools.map(school => school.id),
                    loading: false
                }
            };

        case actionTypes.FETCH_SCHOOL:
            return {...state, school: {...state.school, loading: true}};
        case actionTypes.FETCH_SCHOOL_SUCCESS:
            return {
                ...state,
                fetched: merge(state.fetched, [action.payload.school]),
                school: {
                    ...state.school,
                    id: action.payload.school.id,
                    loading: false
                }
            };
        case actionTypes.RECEIVE_SCHOOLS:
            return {
                ...state,
                fetched: merge(state.fetched, action.payload.schools)
            };
        default:
            return {...state};
    }
};

export default schools;