export default {
    clientId: process.env.REACT_APP_CLIENT_ID,
    customReports: {
        url: process.env.REACT_APP_CUSTOM_REPORTS_URL,
        apiToken: process.env.REACT_APP_CUSTOM_REPORTS_API_TOKEN,
        databaseId: process.env.REACT_APP_CUSTOM_REPORTS_DATABASE_ID,
        script: process.env.REACT_APP_CUSTOM_REPORTS_SCRIPT,
        stylesheet: process.env.REACT_APP_CUSTOM_REPORTS_STYLESHEET,
    },
    webUrl: process.env.REACT_APP_WEB_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    authorizeNet: {
        apiLoginId: process.env.REACT_APP_AUHTORIZE_NET_API_LOGIN_ID,
        clientKey: process.env.REACT_APP_AUHTORIZE_NET_CLIENT_KEY,
        environment: process.env.REACT_APP_AUTHORIZE_NET_ENVIRONMENT
    }
};
