import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated, isUserAuthorized } from './helpers/auth';
import { USER_ROLE_ADMIN } from "./constants/generic";
import { useSelector } from "react-redux";
import UnauthorizedPage from "./pages/UnauthorizedPage";

const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword'));
const ChangePassword = React.lazy(() => import('./pages/auth/ChangePassword'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Users = React.lazy(() => import('./pages/users/Index'));
const UserCreate = React.lazy(() => import('./pages/users/Create'));
const UserEdit = React.lazy(() => import('./pages/users/Edit'));
const Plans = React.lazy(() => import('./pages/plans/Index'));
const PlanCreate = React.lazy(() => import('./pages/plans/Create'));
const PlanEdit = React.lazy(() => import('./pages/plans/Edit'));
const Schools = React.lazy(() => import('./pages/schools/Index'));
const SchoolCreate = React.lazy(() => import('./pages/schools/Create'));
const SchoolShortCodes = React.lazy(() => import('./pages/schools/SchoolShortCodes'));
const SchoolEdit = React.lazy(() => import('./pages/schools/Edit'));
const EnrollmentPeriods = React.lazy(() => import('./pages/enrollment-periods/Index'));
const EnrollmentPeriodCreate = React.lazy(() => import('./pages/enrollment-periods/Create'));
const EnrollmentPeriodEdit = React.lazy(() => import('./pages/enrollment-periods/Edit'));
const SchoolParamVersions = React.lazy(() => import('./pages/schools/param-versions/Index'));
const SchoolParamVersionCreate = React.lazy(() => import('./pages/schools/param-versions/Create'));
const SchoolParamVersionEdit = React.lazy(() => import('./pages/schools/param-versions/Edit'));
const Transactions = React.lazy(() => import('./pages/transactions/Index'));
const TransactionEdit = React.lazy(() => import('./pages/transactions/Edit'));
const Students = React.lazy(() => import('./pages/students/Index'));
const Student = React.lazy(() => import('./pages/students/Show'));
const StudentCreate = React.lazy(() => import('./pages/students/Create'));
const StudentEdit = React.lazy(() => import('./pages/students/Edit'));
const StudentEnroll = React.lazy(() => import('./pages/students/Enroll'));
const StudentEdits = React.lazy(() => import('./pages/student-edits/Index'));
const CustomFields = React.lazy(() => import('./pages/custom-fields/Index'));
const CustomFieldCreate = React.lazy(() => import('./pages/custom-fields/Create'));
const CustomFieldEdit = React.lazy(() => import('./pages/custom-fields/Edit'));
const Carriers = React.lazy(() => import('./pages/carriers/Index'));
const CarrierCreate = React.lazy(() => import('./pages/carriers/Create'));
const CarrierEdit = React.lazy(() => import('./pages/carriers/Edit'));
const CarrierParamVersions = React.lazy(() => import('./pages/carriers/param-versions/Index'));
const CarrierParamVersionCreate = React.lazy(() => import('./pages/carriers/param-versions/Create'));
const CarrierParamVersionEdit = React.lazy(() => import('./pages/carriers/param-versions/Edit'));
const Benefits = React.lazy(() => import('./pages/benefits/Index'));
const BenefitCreate = React.lazy(() => import('./pages/benefits/Create'));
const BenefitEdit = React.lazy(() => import('./pages/benefits/Edit'));
const BenefitParamVersions = React.lazy(() => import('./pages/benefits/param-versions/Index'));
const BenefitParamVersionCreate = React.lazy(() => import('./pages/benefits/param-versions/Create'));
const BenefitParamVersionEdit = React.lazy(() => import('./pages/benefits/param-versions/Edit'));
const Reports = React.lazy(() => import('./pages/reports/Index'));
const CustomReports = React.lazy(() => import('./pages/custom-reports/Index'));
const CustomReportCreate = React.lazy(() => import('./pages/custom-reports/Create'));
const Coverage = React.lazy(() => import('./pages/coverages/Show'));
const CoverageEdit = React.lazy(() => import('./pages/coverages/Edit'));
const CoverageCreate = React.lazy(() => import('./pages/coverages/Create'));
const CoverageUpgrade = React.lazy(() => import('./pages/coverages/Upgrade'));

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const user = useSelector(state => state.profile.user);

    if (roles.indexOf('*') === -1 && !isUserAuthorized(user.role, roles)) {
        return <UnauthorizedPage />;
    }

    return (
        <Route {...rest} render={props => {
            if (!isUserAuthenticated()) {
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            return <Component {...props} />
        }} />
    )
};

const routes = [
    { path: '/login', name: 'login', component: Login, route: Route, exact: true },
    { path: '/logout', name: 'Logout', component: Logout, route: Route, exact: true },
    {
        path: '/forget-password',
        name: 'forget_password',
        component: ForgetPassword,
        route: Route,
        exact: true
    },
    {
        path: '/reset-password/:token',
        name: 'reset_password',
        component: ResetPassword,
        route: Route
    },
    {
        path: '/change-password',
        name: 'change_password',
        component: ChangePassword,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    { path: '/', name: 'dashboard', component: Dashboard, route: PrivateRoute, roles: ['*'], exact: true },
    { path: '/users', name: 'users.index', component: Users, route: PrivateRoute, roles: [USER_ROLE_ADMIN], exact: true },
    {
        path: '/users/create',
        name: 'users.create',
        component: UserCreate,
        route: PrivateRoute,
        roles: [USER_ROLE_ADMIN],
        exact: true
    },
    {
        path: '/users/:userId/edit',
        name: 'users.edit',
        component: UserEdit,
        route: PrivateRoute,
        roles: [USER_ROLE_ADMIN],
        exact: true
    },
    {
        path: '/custom-fields',
        name: 'custom-fields.index',
        component: CustomFields,
        route: PrivateRoute,
        roles: [USER_ROLE_ADMIN],
        exact: true
    },
    {
        path: '/custom-fields/create',
        name: 'custom-fields.create',
        component: CustomFieldCreate,
        route: PrivateRoute,
        roles: [USER_ROLE_ADMIN],
        exact: true
    },
    {
        path: '/custom-fields/:id/edit',
        name: 'custom-fields.edit',
        component: CustomFieldEdit,
        route: PrivateRoute,
        roles: [USER_ROLE_ADMIN],
        exact: true
    },
    { path: '/plans', name: 'plans.index', component: Plans, route: PrivateRoute, roles: ['*'], exact: true },
    {
        path: '/plans/create',
        name: 'plans.create',
        component: PlanCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    { path: '/plans/:id/edit', name: 'plans.edit', component: PlanEdit, route: PrivateRoute, roles: ['*'], exact: true },
    { path: '/schools', name: 'schools.index', component: Schools, route: PrivateRoute, roles: ['*'], exact: true },
    {
        path: '/schools/create',
        name: 'schools.create',
        component: SchoolCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/schools/:id/shortcodes',
        name: 'schools.shortcodes',
        component: SchoolShortCodes,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/schools/:id/edit',
        name: 'schools.edit',
        component: SchoolEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/schools/:schoolId/enrollment-periods',
        name: 'schools.enrollment-periods.index',
        component: EnrollmentPeriods,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/schools/:schoolId/enrollment-periods/create',
        name: 'schools.enrollment-periods.create',
        component: EnrollmentPeriodCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/schools/:schoolId/enrollment-periods/:enrollmentPeriodId/edit',
        name: 'schools.enrollment-periods.edit',
        component: EnrollmentPeriodEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/schools/:schoolId/param-versions',
        name: 'schools.param-versions.index',
        component: SchoolParamVersions,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/schools/:schoolId/param-versions/create',
        name: 'schools.param-versions.create',
        component: SchoolParamVersionCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/schools/:schoolId/param-versions/:paramVersionId/edit',
        name: 'schools.param-versions.edit',
        component: SchoolParamVersionEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/transactions',
        name: 'transactions.index',
        component: Transactions,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/transactions/:transactionId/edit',
        name: 'transactions.edit',
        component: TransactionEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    { path: '/students', name: 'students.index', component: Students, route: PrivateRoute, roles: ['*'], exact: true },
    {
        path: '/students/create',
        name: 'students.create',
        component: StudentCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/students/:studentId',
        name: 'students.show',
        component: Student,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/students/:studentId/edit',
        name: 'students.edit',
        component: StudentEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/students/:studentId/enroll',
        name: 'students.enroll',
        component: StudentEnroll,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/student-edits',
        name: 'student-edits.index',
        component: StudentEdits,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },

    { path: '/carriers', name: 'carriers.index', component: Carriers, route: PrivateRoute, roles: ['*'], exact: true },
    {
        path: '/carriers/create',
        name: 'carriers.create',
        component: CarrierCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/carriers/:id/edit',
        name: 'carriers.edit',
        component: CarrierEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/carriers/:carrierId/param-versions',
        name: 'carriers.param-versions.index',
        component: CarrierParamVersions,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/carriers/:carrierId/param-versions/create',
        name: 'carriers.param-versions.create',
        component: CarrierParamVersionCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/carriers/:carrierId/param-versions/:paramVersionId/edit',
        name: 'carriers.param-versions.edit',
        component: CarrierParamVersionEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },

    { path: '/benefits', name: 'benefits.index', component: Benefits, route: PrivateRoute, roles: ['*'], exact: true },
    {
        path: '/benefits/create',
        name: 'benefits.create',
        component: BenefitCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/benefits/:id/edit',
        name: 'benefits.edit',
        component: BenefitEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/benefits/:benefitId/param-versions',
        name: 'benefits.param-versions.index',
        component: BenefitParamVersions,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/benefits/:benefitId/param-versions/create',
        name: 'benefits.param-versions.create',
        component: BenefitParamVersionCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/benefits/:benefitId/param-versions/:paramVersionId/edit',
        name: 'benefits.param-versions.edit',
        component: BenefitParamVersionEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },

    { path: '/reports', name: 'reports.index', component: Reports, route: PrivateRoute, roles: ['*'], exact: true },
    {
        path: '/custom-reports',
        name: 'custom-reports.index',
        component: CustomReports,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/custom-reports/create',
        name: 'custom-reports.create',
        component: CustomReportCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/coverages/:coverageId',
        name: 'coverages.show',
        component: Coverage,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/coverages/:dependentId/edit',
        name: 'coverages.edit',
        component: CoverageEdit,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/coverages/:coverageId/create',
        name: 'coverages.create',
        component: CoverageCreate,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
    {
        path: '/coverages/:coverageId/upgrade',
        name: 'coverages.upgrade',
        component: CoverageUpgrade,
        route: PrivateRoute,
        roles: ['*'],
        exact: true
    },
];

export { routes };
