import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {fetchUsersStart, fetchUsersSuccess, fetchUsersFailure} from './actions';
import api from "../../helpers/api";
import {FETCH_USERS} from "../../constants/action-types/users";
import Paginator from "../../helpers/paginator";
import {USER_ROLE_ADMIN, USER_ROLE_BPG_MANAGER} from "../../constants/generic";

export function* watchFetchUsers() {
    yield takeLatest(FETCH_USERS, function* (action) {
        try {
            yield put(fetchUsersStart());

            const response = yield call(() => {
                return api.fetchUsers({
                    ...action.payload.filters,
                    role: [USER_ROLE_ADMIN, USER_ROLE_BPG_MANAGER].join(',')
                });
            });

            const {data, meta} = response.data;

            yield put(fetchUsersSuccess(data, new Paginator(meta)));
        } catch {
            yield put(fetchUsersFailure());
        }
    });
}

function* usersSaga() {
    yield all([
        fork(watchFetchUsers)
    ]);
}

export default usersSaga;
