import * as actionTypes from '../../constants/action-types/schools';

export const fetchSchools = () => ({
    type: actionTypes.FETCH_SCHOOLS
});

export const fetchSchoolsStart = () => ({
    type: actionTypes.FETCH_SCHOOLS_START
});

export const fetchSchoolsSuccess = (schools) => ({
    type: actionTypes.FETCH_SCHOOLS_SUCCESS,
    payload: {schools}
});

export const fetchSchoolsFailure = () => ({
    type: actionTypes.FETCH_SCHOOLS_FAILURE
});

export const fetchSchool = (id) => ({
    type: actionTypes.FETCH_SCHOOL,
    payload: {id}
});

export const fetchSchoolStart = () => ({
    type: actionTypes.FETCH_SCHOOL_START
});

export const fetchSchoolSuccess = (school) => ({
    type: actionTypes.FETCH_SCHOOL_SUCCESS,
    payload: {school}
});

export const fetchSchoolFailure = () => ({
    type: actionTypes.FETCH_SCHOOL_FAILURE
});

export const receiveSchools = (schools) => ({
    type: actionTypes.RECEIVE_SCHOOLS,
    payload: {schools}
});