import * as actionTypes from "../../constants/action-types/plans";
import { merge } from "../../helpers/generic";

const INIT_STATE = {
    fetched: [],
    plans: { ids: [], loading: false },
};

const plans = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.SORT_PLANS: {
            return {...state};
        }
        case actionTypes.FETCH_PLANS:
            return { ...state, plans: { ...state.plans, loading: true } };
        case actionTypes.FETCH_PLANS_SUCCESS:
            return {
                ...state,
                fetched: merge(state.fetched, action.payload.plans),
                plans: {
                    ...state.plans,
                    ids: action.payload.plans.map((plan) => plan.id),
                    loading: false,
                },
            };
        default:
            return { ...state };
    }
};

export default plans;
