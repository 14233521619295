import {
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN,
    LOGOUT,
    LOGOUT_SUCCESS,
    FORGET_PASSWORD_OPEN,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    RESET_PASSWORD_OPEN,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED
} from '../../constants/action-types/auth';

export const login = (email, password) => ({
    type: LOGIN,
    payload: {email, password}
});

export const loginStart = () => ({
    type: LOGIN_START
});

export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: {user}
});

export const loginFailed = (error) => ({
    type: LOGIN_FAILED,
    payload: {error}
});

export const logout = (history) => ({
    type: LOGOUT,
    payload: {history}
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS
});

export const forgetPasswordOpen = () => ({
    type: FORGET_PASSWORD_OPEN
});

export const forgetPassword = (email, verification_code) => ({
    type: FORGET_PASSWORD,
    payload: {email, verification_code}
});

export const forgetPasswordSuccess = () => ({
    type: FORGET_PASSWORD_SUCCESS
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error
});

export const resetPasswordOpen = () => ({
    type: RESET_PASSWORD_OPEN
});

export const resetPassword = (values) => ({
    type: RESET_PASSWORD,
    payload: values
});

export const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS
});

export const resetPasswordFailed = (errors) => ({
    type: RESET_PASSWORD_FAILED,
    payload: errors
});
