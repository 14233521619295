export const FETCH_SCHOOLS = 'FETCH_SCHOOLS';
export const FETCH_SCHOOLS_START = 'FETCH_SCHOOLS_START';
export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export const FETCH_SCHOOLS_FAILURE = 'FETCH_SCHOOLS_FAILURE';

export const FETCH_SCHOOL = 'FETCH_SCHOOL';
export const FETCH_SCHOOL_START = 'FETCH_SCHOOL_START';
export const FETCH_SCHOOL_SUCCESS = 'FETCH_SCHOOL_SUCCESS';
export const FETCH_SCHOOL_FAILURE = 'FETCH_SCHOOL_FAILURE';

export const SAVE_SCHOOL = 'SAVE_SCHOOL';
export const RECEIVE_SCHOOLS = 'RECEIVE_SCHOOLS';
