import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {FETCH_ME} from '../../constants/action-types/profile';
import api from "../../helpers/api";
import {fetchMeFailed, fetchMeStart, fetchMeSuccess} from "./actions";

export function* watchFetchMe(): any {
    yield takeLatest(FETCH_ME, function* () {
        try {
            yield put(fetchMeStart());

            const response = yield call(() => {
                return api.fetchMe();
            });

            yield put(fetchMeSuccess(response.data.data));
        } catch (error) {
            yield put(fetchMeFailed(error.response.data.message));
        }
    });
}

function* profileSaga(): any {
    yield all([
        fork(watchFetchMe)
    ]);
}

export default profileSaga;
