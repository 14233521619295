import {FETCH_ME_FAILED, FETCH_ME_START, FETCH_ME_SUCCESS} from "../../constants/action-types/profile";

const INIT_STATE = {
    user: null,
    fetchMe: {
        loading: false
    }
};


const profile = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ME_START:
            return {...state, fetchMe: {...state.fetchMe, loading: true}};
        case FETCH_ME_SUCCESS:
            return {...state, user: action.payload.user, fetchMe: {...state.login, loading: false}};
        case FETCH_ME_FAILED:
            return {...state, fetchMe: {...state.fetchMe, loading: false}};
        default:
            return {...state};
    }
};

export default profile;