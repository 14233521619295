import {
    PLAN_COVERAGE_STUDENT_AND_CHILDREN,
    PLAN_COVERAGE_STUDENT_AND_FAMILY,
    PLAN_COVERAGE_STUDENT_AND_SPOUSE,
    PLAN_COVERAGE_STUDENT, PLAN_COVERAGE_STUDENT_AND_FAMILY_PLUS,
} from "../constants/generic";

export const merge = (first, second) => {
    let merged = {...first};

    second.forEach((record) => (merged[record.id] = record));

    return merged;
};

export const titleCase = (string) => {
    if (!string) return string;

    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
};

export const getDependentMaxCount = (planId, plans) => {
    let maxCount = 0;

    if (planId) {
        const coverage = plans.filter((p) => p.id == planId)[0].coverage;

        if (coverage == PLAN_COVERAGE_STUDENT_AND_SPOUSE) {
            maxCount = 1;
        } else if (coverage == PLAN_COVERAGE_STUDENT_AND_CHILDREN) {
            maxCount = null;
        } else if (coverage == PLAN_COVERAGE_STUDENT_AND_FAMILY) {
            maxCount = null;
        } else if (coverage == PLAN_COVERAGE_STUDENT_AND_FAMILY_PLUS) {
            maxCount = 1;
        }
    }

    return maxCount;
};

export const getPlan = (planId, plans) => {
    return plans.filter((p) => p.id == planId)[0];
};

export const orderBenefit = (array, order) => {
    array.sort((a, b) => {
        let aValue = a.value, bValue = b.value;

        if (!a.selected) return 1;

        return order.indexOf(aValue) > order.indexOf(bValue) ? 1 : -1;
    });

    return array;
};

export const parseFilters = (filters) => {
    const parsed = {};

    for (let key in filters) {
        if (filters.hasOwnProperty(key) && filters[key] !== null && filters[key] !== "") {
            parsed[key] = filters[key];
        }
    }

    return parsed;
}