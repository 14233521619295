import axios, {AxiosInstance} from 'axios';
import {getToken} from "./auth";
import config from "../config";

class Api {
    axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: config.apiUrl,
            //  baseURL: 'http://127.0.0.1:8000/api',
            headers: {
                'ContentType': 'application/json'
            }
        });

        this.axios.interceptors.request.use(async (config) => {
            let token = getToken();
            if (token) config.headers.Authorization = 'Bearer ' + token;

            return config;
        });
    }

    login(email, password) {
        return this.axios.post('/oauth/token', {
            grant_type: 'password',
            client_id: config.clientId,
            username: email,
            password,
            scope: 'admin'
        });
    }

    fetchMe() {
        return this.axios.get('/profile');
    }

    fetchUsers(filters) {
        return this.axios.get('/users', {params: filters});
    }

    fetchUser(userId) {
        return this.axios.get(`/users/${userId}`);
    }

    createUser(values) {
        return this.axios.post(`/users`, values);
    }

    updateUser(userId, values) {
        return this.axios.patch(`/users/${userId}`, values);
    }

    fetchPlans(filters) {
        return this.axios.get('/plans', {params: filters});
    }

    fetchSearchPlans(keyword, active, offset) {
        return this.axios.get(`/plans`, {params: {keyword, active, offset}});

    }


    fetchSchools() {
        return this.axios.get('/schools');
    }

    fetchSchool(id) {
        return this.axios.get(`/schools/${id}`);
    }

    fetchShortCodes(id) {
        return this.axios.get(`/schools/${id}/shortcodes`);

    }

    createSchool(values) {
        return this.axios.post('/schools', values);
    }


    updateSchool(id, values) {
        return this.axios.patch(`/schools/${id}`, values);
    }

    fetchPlan(id) {
        return this.axios.get(`/plans/${id}`);
    }

    createPlan(values) {
        return this.axios.post('/plans', values);
    }

    updatePlan(id, values) {
        return this.axios.patch(`/plans/${id}`, values);
    }

    fetchLookups(requested) {
        return this.axios.get(`/lookups`, {params: {requested}});
    }

    fetchEnrollmentPeriods(schoolId) {
        return this.axios.get(`/schools/${schoolId}/enrollment-periods`);
    }

    fetchEnrollmentPeriod(schoolId, enrollmentPeriodId) {
        return this.axios.get(`/schools/${schoolId}/enrollment-periods/${enrollmentPeriodId}`);
    }

    createEnrollmentPeriod(schoolId, values) {
        return this.axios.post(`/schools/${schoolId}/enrollment-periods`, values);
    }

    updateEnrollmentPeriod(schoolId, enrollmentPeriodId, values) {
        return this.axios.patch(`/schools/${schoolId}/enrollment-periods/${enrollmentPeriodId}`, values);
    }

    fetchSchoolParamVersions(schoolId) {
        return this.axios.get(`/schools/${schoolId}/param-versions`);
    }

    fetchSchoolParamVersion(schoolId, paramVersionId) {
        return this.axios.get(`/schools/${schoolId}/param-versions/${paramVersionId}`);
    }

    createSchoolParamVersion(schoolId, values) {
        return this.axios.post(`/schools/${schoolId}/param-versions`, values);
    }

    updateSchoolParamVersion(schoolId, paramVersionId, values) {
        return this.axios.patch(`/schools/${schoolId}/param-versions/${paramVersionId}`, values);
    }

    fetchEnrollments(filters) {
        return this.axios.get(`/enrollments`, {params: filters});
    }

    fetchTransactions(filters) {
        return this.axios.get(`/transactions`, {params: filters});
    }

    fetchTransaction(transactionId) {
        return this.axios.get(`/transactions/${transactionId}`);
    }

    updateTransaction(transactionId, values) {
        return this.axios.patch(`/transactions/${transactionId}`, values);
    }

    fetchStudents(filters) {
        return this.axios.get(`/students`, {params: filters});
    }

    fetchStudent(studentId) {
        return this.axios.get(`/students/${studentId}`);
    }

    createStudent(values) {
        return this.axios.post(`/students`, values);
    }

    updateStudent(studentId, values) {
        return this.axios.patch(`/students/${studentId}`, values);
    }

    fetchStudentEdits(filters) {
        return this.axios.get(`/student-edits`, {params: filters});
    }

    fetchPlanTypes() {
        return this.axios.get(`/plan-types`);
    }

    fetchPlanType(id) {
        return this.axios.get(`/plan-types/${id}`);
    }

    createPlanType(values) {
        return this.axios.post(`/plan-types`, values);
    }

    updatePlanType(id, values) {
        return this.axios.patch(`/plan-types/${id}`, values);
    }

    changePassword(password) {
        return this.axios.post(`/profile/change-password`, {password});
    }

    search(q) {
        return this.axios.get(`/search`, {params: {q}});
    }

    fetchCustomFields() {
        return this.axios.get(`/custom-fields`);
    }

    fetchCustomField(id) {
        return this.axios.get(`/custom-fields/${id}`);
    }

    createCustomField(values) {
        return this.axios.post(`/custom-fields`, values);
    }

    updateCustomField(id, values) {
        return this.axios.patch(`/custom-fields/${id}`, values);
    }

    fetchAvailablePlansForStudent(studentId, enrollmentPeriodId, schoolId) {
        return this.axios.get(`/students/${studentId}/available-plans?enrollment-period-id=${enrollmentPeriodId}&school-id=${schoolId}`);
    }

    fetchEnrollmentPeriodsForStudent(studentId, schoolId) {
        return this.axios.get(`/students/${studentId}/enrollment-periods?school-id=${schoolId}`);
    }

    fetchActiveEnrollmentForStudent(studentId, schoolId) {
        return this.axios.get(`/students/${studentId}/active-enrollment?school-id=${schoolId}`);
    }

    enroll(studentId, values) {
        return this.axios.post(`/students/${studentId}/enroll`, values);
    }

    fetchCarriers(q) {
        return this.axios.get('/carriers', {params: q});
    }

    fetchCarrier(id) {
        return this.axios.get(`/carriers/${id}`);
    }

    createCarrier(values) {
        return this.axios.post('/carriers', values);
    }

    updateCarrier(id, values) {
        return this.axios.patch(`/carriers/${id}`, values);
    }

    fetchCarrierParamVersions(carrierId) {
        return this.axios.get(`/carriers/${carrierId}/param-versions`);
    }

    fetchCarrierParamVersion(carrierId, paramVersionId) {
        return this.axios.get(`/carriers/${carrierId}/param-versions/${paramVersionId}`);
    }

    createCarrierParamVersion(carrierId, values) {
        return this.axios.post(`/carriers/${carrierId}/param-versions`, values);
    }

    updateCarrierParamVersion(carrierId, paramVersionId, values) {
        return this.axios.patch(`/carriers/${carrierId}/param-versions/${paramVersionId}`, values);
    }

    fetchBenefits() {
        return this.axios.get('/benefits');
    }

    fetchBenefit(id) {
        return this.axios.get(`/benefits/${id}`);
    }

    createBenefit(values) {
        return this.axios.post('/benefits', values);
    }

    updateBenefit(id, values) {
        return this.axios.patch(`/benefits/${id}`, values);
    }

    fetchBenefitParamVersions(benefitId) {
        return this.axios.get(`/benefits/${benefitId}/param-versions`);
    }

    fetchBenefitParamVersion(benefitId, paramVersionId) {
        return this.axios.get(`/benefits/${benefitId}/param-versions/${paramVersionId}`);
    }

    createBenefitParamVersion(benefitId, values) {
        return this.axios.post(`/benefits/${benefitId}/param-versions`, values);
    }

    updateBenefitParamVersion(benefitId, paramVersionId, values) {
        return this.axios.patch(`/benefits/${benefitId}/param-versions/${paramVersionId}`, values);
    }

    fetchReports() {
        return this.axios.get('/reports');
    }

    createReport(filters) {
        return this.axios.post('/reports', filters);
    }

    fetchCustomReports() {
        return this.axios.get('/custom-reports');
    }

    regenerateCustomReport(id) {
        return this.axios.post(`/custom-reports/${id}/regenerate`);
    }

    deleteCustomReport(id) {
        return this.axios.delete(`/custom-reports/${id}`);
    }

    forgetPassword(email, verification_code) {
        return this.axios.post('password/forget', {email, verification_code});
    }

    resetPassword(values) {
        return this.axios.post('/password/reset', values)
    }

    fetchDashboard(schoolId) {
        return this.axios.get('/dashboard', {params: {schoolId}})
    }

    fetchDashboardTotalCommission(schoolId) {
        return this.axios.get('/dashboard/total-commission', {params: {schoolId}})
    }


    fetchCoverage(coverageId) {
        return this.axios.get(`/coverages/${coverageId}`);
    }

    upgradeCoverage(coverageId, values) {
        return this.axios.post(`/coverages/${coverageId}/upgrade`, values);
    }

    createDependents(coverageId, values) {
        return this.axios.post(`/dependents/${coverageId}/store`, values);
    }

    updateDependents(dependentId, values) {
        return this.axios.put(`/dependents/${dependentId}/update`, values);
    }

    deleteDependent(dependentId) {
        return this.axios.delete(`/dependents/${dependentId}/destroy`);
    }

    updateFacility(dependentid, values) {
        return this.axios.post(`/dependents/${dependentid}/update-facility`, values);
    }

    fetchCoverageAvailableUpgrades(coverageId) {
        return this.axios.get(`/coverages/${coverageId}/available-upgrades`);
    }
}

export default new Api();
