import jwtDecode from 'jwt-decode';
import {Cookies} from "react-cookie";

const setToken = (token) => {
    const cookies = new Cookies();

    cookies.set("token", JSON.stringify(token));
};

const clearToken = () => {
    const cookies = new Cookies();

    cookies.remove("token");
};

const getToken = () => {
    const cookies = new Cookies();

    const token = cookies.get("token");

    return token ? token.access_token : null;
};

const isUserAuthenticated = () => {
    const token = getToken();
    if (!token) return false;

    const parsedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return parsedToken.exp > currentTime;
};

const isUserAuthorized = (userRole, roles) => {
    return roles.indexOf('*') !== -1 || !!roles.filter(role => userRole == role).length;
};

export {isUserAuthenticated, getToken, setToken, clearToken, isUserAuthorized};