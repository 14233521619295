export const USER_ROLE_ADMIN = 1;
export const USER_ROLE_BPG_MANAGER = 2;

export const USER_GENDER_MALE = "male";
export const USER_GENDER_FEMALE = "female";
export const USER_GENDER_OTHER = "other";

export const SCHOOL_DEFAULT_IDENTIFIER = "student-card-id";

export const REPORT_TYPE_RECON = 1;
export const REPORT_TYPE_ELIGIBILITY = 2;
export const REPORT_TYPE_STUDENT_EDIT = 3;

export const TRANSACTION_TYPE_CREDIT_CARD = 1;
export const TRANSACTION_TYPE_CHECK = 2;
export const TRANSACTION_TYPE_MANUAL = 4;
export const TRANSACTION_TYPE_ACH = 5;
export const TRANSACTION_TYPE_UNIVERSITY_UNION = 6;

export const TRANSACTION_STATUS_REFUND = 3;
export const TRANSACTION_STATUS_PENDING = 4;

export const SCHOOL_IDENTIFIER_STUDENT_CARD_ID = "student-card-id";
export const SCHOOL_IDENTIFIER_SSN = "ssn";

export const PLAN_COVERAGE_STUDENT = "1";
export const PLAN_COVERAGE_STUDENT_AND_SPOUSE = "2";
export const PLAN_COVERAGE_STUDENT_AND_CHILDREN = "3";
export const PLAN_COVERAGE_STUDENT_AND_FAMILY = "4";
export const PLAN_COVERAGE_STUDENT_AND_FAMILY_PLUS = "5";

export const DEPENDENT_RELATION_SPOUSE = 2;
export const DEPENDENT_RELATION_CHILD = 3;

export const COVERAGE_STATUS_ACTIVE = 1;
