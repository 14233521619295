import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {FORGET_PASSWORD, LOGIN, LOGOUT, RESET_PASSWORD} from '../../constants/action-types/auth';
import {
    loginStart,
    loginSuccess,
    loginFailed,
    logoutSuccess,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    resetPasswordSuccess,
    resetPasswordFailed
} from './actions';
import api from "../../helpers/api";
import {clearToken, setToken} from "../../helpers/auth";
import {fetchMe} from "../profile/actions";

export function* watchLogin(): any {
    yield takeLatest(LOGIN, function* (action) {
        try {
            yield put(loginStart());

            const loginResponse = yield call(() => {
                return api.login(action.payload.email, action.payload.password);
            });

            setToken(loginResponse.data);

            yield put(fetchMe());

            yield put(loginSuccess());
        } catch (error) {
            const {response} = error;
            let message = response.data.message;
            if (response.status === 400) {
                message = "Invalid email or password."
            }
            yield put(loginFailed(message));
        }
    });
}

export function* watchLogout(): any {
    yield takeLatest(LOGOUT, function* (action) {
        try {
            clearToken();

            yield put(logoutSuccess());

            yield call(() => {
                action.payload.history.replace('/login');
            });
        } catch (error) {
            yield put(loginFailed(error.response.data.message));
        }
    });
}

export function* watchForgetPassword(): any {
    yield takeLatest(FORGET_PASSWORD, function* (action) {
        try {
            yield call(() => {
                return api.forgetPassword(action.payload.email, action.payload.verification_code);
            });
            yield put(forgetPasswordSuccess());
        } catch (error) {
            yield put(forgetPasswordFailed(error.response.data.message));
        }
    });
}

export function* watchResetPassword(): any {
    yield takeLatest(RESET_PASSWORD, function* (action) {
        try {
            yield call(() => {
                return api.resetPassword(action.payload);
            });
            yield put(resetPasswordSuccess());
        } catch (error) {
            yield put(resetPasswordFailed([].concat.apply([], Object.values(error.response.data.errors))));
        }
    });
}


function* authSaga(): any {
    yield all([
        fork(watchLogin), fork(watchLogout),
        fork(watchForgetPassword), fork(watchResetPassword)
    ]);
}

export default authSaga;
