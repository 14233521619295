import * as actionTypes from '../../constants/action-types/users';
import Paginator from "../../helpers/paginator";
import {merge} from "../../helpers/generic";

const INIT_STATE = {
    fetched: [],
    users: {ids: [], paginator: new Paginator, loading: false}
};

const auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERS_START:
            return {...state, users: {...state.users, loading: true}};
        case actionTypes.FETCH_USERS_SUCCESS:
            return {
                ...state,
                fetched: merge(state.fetched, action.payload.users),
                users: {
                    ...state.users,
                    ids: action.payload.users.map(user => user.id),
                    paginator: action.payload.paginator,
                    loading: false
                }
            };
        default:
            return {...state};
    }
};

export default auth;