import {SET_STUDENT_FILTERS} from "../../constants/action-types/students";

const INIT_STATE = {
    filters: {}
};

const students = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_STUDENT_FILTERS:
            return {...state, filters: action.payload.filters};
        default:
            return {...state};
    }
};

export default students;
